.stepItemIncompleteFirst {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemIncompleteCenter {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemIncompleteLast {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemInprogressFirst {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemInprogressCenter {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemInprogressLast {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemCompleteFirst {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemCompleteCenter {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.stepItemCompleteLast {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .progressIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .orderProgressItem {
    overflow: hidden;
    align-self: center;
    width: 0.125rem;
    flex: 1 1 auto;
  }

  .stepIndicator {
    align-self: center;
    flex: 0 0 1rem;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}