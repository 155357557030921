.orderInProgressBanner {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  height: 3rem;
  background-color: rgba(85, 78, 255, 1);

  .leftIcon {
    padding: 0.8rem 0rem 0.8rem 1rem;
    position: absolute;
    left: 0;
  }

  .centerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    p {
      line-height: 1.5rem;
    }
  }
}