.paymentInfoPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .paymentInfoBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1rem 1rem;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .paymentInfoPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .paymentInfoBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .paymentInfoPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    .paymentInfoBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4.5rem 6rem 4.5rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .paymentInfoPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .paymentInfoBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4.5rem 6rem 4.5rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1440px) {
  .paymentInfoPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .paymentInfoBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4.5rem 6rem 4.5rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}