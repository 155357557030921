.autocompleteSuggestions {
  padding: 8px 10px 8px 10px;
  background-color: #F4F4F4;
  border-top: 1px solid #EBEBEB;
  &:first-child {
    padding: 12px 10px 8px 10px;
    border-top: none;
  }
  &:last-child {
    padding: 8px 10px 12px 10px;
  }
}

.autocompleteBox {
  margin-bottom: 0;
  padding: unset;
}
