.simpleLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .topNav {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    z-index: 1;
  }

  .body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .bodyContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .startBlockPage {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .simpleLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .topNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .startBlockPage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .simpleLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .topNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .startBlockPage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .simpleLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .topNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .startBlockPage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .simpleLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .topNav {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .startBlockPage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
  }
}