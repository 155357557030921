.foodItemCardDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .price {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    background-position: center;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.foodItemCardDefault:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .price {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.foodItemCardDefault:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(244, 244, 244);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .price {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.foodItemCardDefault:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);
  border: 2px solid rgb(106, 101, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .price {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.foodItemCardDefault:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.125rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .price {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.foodItemCardLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .contentGroupOne {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 1.25rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .gontentGroupTwo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroupTwo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 0.75rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentGroupThree {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: flex-start;
    width: 2.75rem;
    flex: 0 0 0.75rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentOne {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .foodItemCardDefault {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .title {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .price {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .foodItemCardDefault:hover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .title {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .price {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .foodItemCardDefault:active {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .title {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .price {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .foodItemCardDefault:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(106, 101, 255);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .title {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .price {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .foodItemCardDefault:disabeld {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.125rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .title {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .price {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .foodItemCardLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 1rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .contentGroupOne {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1.25rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .gontentGroupTwo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .contentGroupTwo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 0.75rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupThree {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: flex-start;
      width: 2.75rem;
      flex: 0 0 0.75rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentOne {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .text {
      display: none;
    }
  
    .title {
      display: none;
    }
  
    .description {
      display: none;
    }
  
    .price {
      display: none;
    }
  
    .image {
      display: none;
    }
  
  }
}

@keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}


@-webkit-keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}