.dividerHorizontalSolidGrey400 {
  background-color: rgb(235, 235, 235);
  height: 0.0625rem;
}
.dividerHorizontalSolidGrey500 {
  background-color: rgb(220, 220, 220);
  height: 0.0625rem;
}
.dividerVerticalSolidGrey400 {
  background-color: rgb(235, 235, 235);
  width: 0.0625rem;
}
.dividerHorizontalDottedGrey500 {
  border: 1px dotted rgb(220, 220, 220);
  height: 0.0625rem;
  border-style: none none dotted;
}
.dividerHorizontalSolidGrey800 {
  border: 1px solid rgb(62, 62, 62);
  height: 0.0625rem;
}
.dividerHorizontalSolidBlue200 {
  border: 1px solid rgb(193, 222, 248);
  height: 0.0625rem;
}