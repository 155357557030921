.startBlockPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(149, 146, 255);

  .startBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 576px) {
  .startBlockPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(149, 146, 255);
  
    .startBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .startBlockPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(149, 146, 255);
  
    .startBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .startBlockPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(149, 146, 255);
  
    .startBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1440px) {
  .startBlockPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(149, 146, 255);
  
    .startBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: center;
      width: 48rem;
      flex: 0 0 auto;
    }
  
  }
}