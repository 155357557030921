.cardInfo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }

  .cardNumber {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .stripeContainer {
    display: block;
    align-self: stretch;
    flex: 0 0 auto;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    background-color: rgb(244, 244, 244);
  }

  .stripeContainerError {
    display: block;
    align-self: stretch;
    flex: 0 0 auto;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    background-color: rgb(244, 244, 244);
    border: 2px red solid;
  }

  .label {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .textInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .row1 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .expirationDate {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 8.75rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .cVV {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 8.75rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}