
.restaurantListPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 9rem;

  .restaurantListBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 0;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }
}

@media (min-width: 576px) {
  .restaurantListPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .restaurantListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 1rem 1.5rem 1rem;
      > * {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantListPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
  
    .restaurantListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 2.5rem 1.5rem 2.5rem;
      > * {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantListPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 255, 255);
  
    .restaurantListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.5rem 2.5rem 2.5rem 2.5rem;
      > * {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantListPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 255, 255);
  
    .restaurantListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4rem 4rem 4rem 4rem;
      > * {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

.Compact {
  margin-top: 6.5rem;
}
