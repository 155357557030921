.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  background-color: rgb(255, 255, 255);
  @media (max-width: 1023px) {
    flex: 1 1 auto;
  }
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(255, 255, 255);
}

.content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);
  > * {
    margin-top: 0.5rem;
  }
}

.innerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  padding: 1rem 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(250, 250, 250);
  padding: 1rem 1rem 1rem 1rem;
}