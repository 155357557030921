.deliveryInfoDeliveryDefaultDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .topDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .deliveryAddress {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .deliveryTime {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .subContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .dropdownToggle {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .deliveryInstruction {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1.625rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .bottomDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}

.deliveryInfoPickupDefaultDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .topDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .pickupTime {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .restaurantAddress {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .bottomDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.deliveryInfoPickupLoadingDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .topDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .contentOne {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 10rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(235, 235, 235);
    height: 1.25rem;
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentTwo {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(235, 235, 235);
    height: 1rem;
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentThree {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(235, 235, 235);
    height: 1rem;
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .bottomDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.deliveryInfoDeliveryDefaultError {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .topDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .deliveryTime {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .subContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .deliveryAddress {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .dropdownToggle {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .deliveryInstruction {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1.625rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;  
  }

  .errorNotification {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    white-space: pre-line;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .bottomDivider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}


@keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}


@-webkit-keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}