.textTitle1LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textTitle1CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textTitle1CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: center;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textTitle2LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textTitle2CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textTitle3LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textTitle3LeftGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
// MANUAL OVERRIDE
.textTitle3LeftGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(116, 116, 116);
  }

}
.textTitle4LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textTitle4LeftGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textTitle4RightGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(0, 0, 0);
  }

}
.textTitle5LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: uppercase;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textTitle4CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textTitle4CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textBody1LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textBody1CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textBody1CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textBody1LeftGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textBody1LeftGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(116, 116, 116);
  }

}
.textBody2LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textBody2CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textBody2CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textBody2LeftGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textBody2LeftGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(116, 116, 116);
  }

}
.textBody2LeftBlue800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(27, 73, 155);
  }

}
.textBody2LeftRed800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(183, 56, 49);
  }

}
.textBody2CenterGreen800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(106, 101, 255);
  }

}
.textBody2LeftGreen800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(106, 101, 255);
  }

}
.textBody2RightGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(116, 116, 116);
  }

}
.textBody2RightGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(189, 189, 189);
  }

}
.textBody3LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textBody3LeftGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(116, 116, 116);
  }

}
.textBody3LeftGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(62, 62, 62);
  }

}
.textBody3LeftGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textBody3LeftRed800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(183, 56, 49);
  }

}
.textBody3CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textBody3CenterGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(62, 62, 62);
  }

}
.textBody3CenterGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textBody3RightGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(116, 116, 116);
  }

}
.textBody3RightGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(189, 189, 189);
  }

}
.textBody4LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textBody5LeftGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(62, 62, 62);
  }

}
.textBody4CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textBody5LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textBody5RightGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(62, 62, 62);
  }

}
.textBody5RightGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(0, 0, 0);
  }

}
.textBody5RightGreen800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(77,146,33);
  }

}
.textBody5CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textBody5CenterGreen800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(106, 101, 255);
  }

}
.textBody5CenterGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textButton1CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}
.textButton1CenterGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(62, 62, 62);
  }

}
.textButton1CenterGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textButton1CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textButton2LeftGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(0, 0, 0);
  }

}
.textButton2CenterGrey1100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(0, 0, 0);
  }

}

.textButton2CenterGreen800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(106, 101, 255);
  }

}
.textButton2CenterGrey600 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textButton2CenterGrey100 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textButton2LeftRed800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(169, 46, 38);
  }

}
.textButton2CenterRed800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(169, 46, 38);
  }

}
.textButton2CenterBlue800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(43, 102, 186);
  }

}

// MANUAL OVERRIDES BELOW
.textButton1CenterPurple800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(106, 101, 255);
  }

}
.textButton2CenterPurple800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(106, 101, 255);
  }

}
.textBody2CenterGrey700 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(116, 116, 116);
  }

}
.textBody2LeftGrey800 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Boing', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(62, 62, 62);
  }

}