.buttonGroup2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  > * {
    margin-top: 0.5rem;
  }

  .primaryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: #aba8ff;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .secondaryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: rgb(245, 245, 245);
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .primaryButton:hover {
    background:#aba8ff;
  }

  .secondaryButton:hover {
    background: rgb(230, 230, 230);
  }

  .primaryButton:focus-visible {
    outline: 2px solid rgb(10, 135, 41);
  }

  .secondaryButton:focus-visible {
    outline: 2px solid rgb(10, 135, 41);
  }

  .primaryButton:active {
    background:#aba8ff;
  }

  .secondaryButton:active {
    background: rgb(218, 218, 218);
  }


  .button {
    display: none;
  }

}

@media (min-width: 1024px) {
  .buttonGroup2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    > * {
      margin-left: 0.5rem;
    }
  
    .primaryButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      background: #aba8ff;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .secondaryButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      background: rgb(245, 245, 245);
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      }
  }
}