.copyLinkField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(235, 235, 235);

  .linkContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .link {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .copyText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}