.dropdownToggle {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  cursor: pointer;

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1.25rem;
  }
}
.dropdownToggle:hover {
  background-color: rgb(244, 244, 244);
}
.dropdownToggle:active {
  background-color: rgb(235, 235, 235);
}
.dropdownToggle:focus-visible {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(10, 135, 41);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1.25rem;
  }
}
.dropdownToggle:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1.25rem;
  }
}
