.stepIndicatorInprogress {
  height: 1rem;
  .content {
    // filter: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(2348%) hue-rotate(133deg) brightness(92%) contrast(92%);
  }

}
.stepIndicatorIncomplete {
  height: 1rem;
  .content {
    filter: brightness(0) saturate(100%) invert(52%) sepia(2%) saturate(10%) hue-rotate(17deg) brightness(85%) contrast(84%);
  }

}
.stepIndicatorComplete {
  height: 1rem;
  .content {
    // filter: brightness(0) saturate(100%) invert(32%) sepia(99%) saturate(544%) hue-rotate(88deg) brightness(98%) contrast(93%);
  }

}