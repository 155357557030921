/**
 *  Fonts:
 *    - Inter (all weights)
 */
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

 @font-face {
    font-family: 'Boing';
    font-weight: 400;
    src: local('Boing'), url(../fonts/Boing/Boing-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Boing';
    font-weight: 500;
    src: local('Boing'), url(../fonts/Boing/Boing-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Boing';
    font-weight: 600;
    src: local('Boing'), url(../fonts/Boing/Boing-Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'Boing';
    font-weight: 700;
    src: local('Boing'), url(../fonts/Boing/Boing-Bold.otf) format('opentype');
}