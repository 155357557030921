.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 0rem;
  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem;
  }
  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem;
  }
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  background-color: rgb(255, 255, 255);
  @media (max-width: 1023px) {
    flex: 1 1 auto;
  }

  border-bottom: 1px solid rgb(235, 235, 235);
  @media (min-width: 1024px) {
    border-bottom: none;
  }
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(255, 255, 255);

  .buttonGroup1 {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      margin-bottom: 8px;
    }

    button:last-child {
      background-color: rgb(245, 245, 245);

      &:hover {
        background-color: rgb(225, 225, 225);
      }
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: left;
      button {
        width: auto;
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
  }
}

.content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);
  > * {
    margin-top: 0.5rem;
  }
}

.innerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  padding: 1rem 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
}
