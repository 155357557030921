.imageSquare {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url('../../../resources/images/Image.png');
  background-size: cover;

}

.imageCircle {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url('../../../resources/images/ImageCircle.png');
  background-size: cover;
  border-radius: 1000px;

}