.iconCartEmptyGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(7500%) hue-rotate(302deg) brightness(105%) contrast(106%);
  }

}
.iconCartEmptyGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(84%) sepia(3%) saturate(2%) hue-rotate(11deg) brightness(89%) contrast(95%);
  }

}
.iconCartEmptyGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(100%) contrast(99%);
  }

}
.iconCartFullGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .contentTwo {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(53deg) brightness(96%) contrast(103%);
  }

  .contentOne {
    width: 100%;
    height: 100%;
    background-color: rgb(10, 135, 41);
  }

}
.iconArrowRightGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(17%) hue-rotate(302deg) brightness(95%) contrast(105%);
  }

}
.iconArrowLeftGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(0%) hue-rotate(108deg) brightness(93%) contrast(103%);
  }

}
.iconChevronDownGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7500%) hue-rotate(346deg) brightness(89%) contrast(111%);
  }

}
.iconChevronUpGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(85%) saturate(7500%) hue-rotate(195deg) brightness(112%) contrast(112%);
  }

}
.iconChevronDownGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(51%) sepia(73%) saturate(5567%) hue-rotate(227deg) brightness(103%) contrast(102%);
  }

}
.iconChevronUpGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(51%) sepia(93%) saturate(5401%) hue-rotate(230deg) brightness(110%) contrast(106%);
  }

}
.iconChevronDownGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(84%) sepia(0%) saturate(36%) hue-rotate(165deg) brightness(92%) contrast(90%);
  }

}
.iconChevronRightGrey700 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(46%) sepia(1%) saturate(763%) hue-rotate(336deg) brightness(97%) contrast(83%);
  }

}
.iconChevronRightGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(305%) hue-rotate(237deg) brightness(86%) contrast(88%);
  }

}
.iconChevronRightGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(305%) hue-rotate(237deg) brightness(100%) contrast(105%);
  }

}
.iconBackGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(7475%) hue-rotate(284deg) brightness(94%) contrast(106%);
  }

}
.iconBackGrey200 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(250, 250, 250);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(96%) saturate(7390%) hue-rotate(327deg) brightness(77%) contrast(100%);
  }

}
.iconBackGrey300 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(244, 244, 244);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(7466%) hue-rotate(64deg) brightness(100%) contrast(98%);
  }

}
.iconBackGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(189, 189, 189);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(136deg) brightness(102%) contrast(104%);
  }

}
.iconCloseGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(2%) saturate(7454%) hue-rotate(352deg) brightness(108%) contrast(100%);
  }

}
.iconCloseGrey200 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(250, 250, 250);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(36%) saturate(981%) hue-rotate(49deg) brightness(86%) contrast(95%);
  }

}
.iconCloseGrey300 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(244, 244, 244);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(0%) sepia(16%) saturate(660%) hue-rotate(65deg) brightness(99%) contrast(100%);
  }

}
.iconCloseGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgb(189, 189, 189);
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(108%) contrast(100%);
  }

}
.iconVeganGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(3499%) hue-rotate(140deg) brightness(95%) contrast(92%);
  }

}
.iconCheckboxUncheckedGrey700 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(0%) hue-rotate(323deg) brightness(95%) contrast(95%);
  }

}
.iconCheckboxUncheckedGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(91%) sepia(1%) saturate(298%) hue-rotate(92deg) brightness(87%) contrast(84%);
  }

}
.iconCheckboxCheckedGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(30%) sepia(63%) saturate(4360%) hue-rotate(130deg) brightness(87%) contrast(92%);
  }

}
.iconCheckboxCheckedGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(88%) sepia(0%) saturate(2136%) hue-rotate(87deg) brightness(90%) contrast(83%);
  }

}
.iconCheckboxIntermediateGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(38%) sepia(57%) saturate(738%) hue-rotate(83deg) brightness(94%) contrast(97%);
  }

}
.iconRadioUncheckedGrey700 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(50%) sepia(6%) saturate(25%) hue-rotate(336deg) brightness(88%) contrast(87%);
  }

}
.iconRadioUncheckedGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(76%) sepia(1%) saturate(279%) hue-rotate(321deg) brightness(101%) contrast(88%);
  }

}
.iconRadioCheckedGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(28%) sepia(93%) saturate(6201%) hue-rotate(143deg) brightness(97%) contrast(92%);
  }

}
.iconRadioCheckedGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(71%) sepia(80%) saturate(0%) hue-rotate(335deg) brightness(93%) contrast(90%);
  }

}
.iconPlusCircleGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7492%) hue-rotate(207deg) brightness(100%) contrast(103%);
  }

}
.iconInfoBlue1000 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(8%) sepia(61%) saturate(6602%) hue-rotate(214deg) brightness(96%) contrast(103%);
  }

}
.iconInfoOutlineGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(12%) hue-rotate(257deg) brightness(104%) contrast(104%);
  }

}
.iconCheckCircleGreen700 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(74%) sepia(58%) saturate(6299%) hue-rotate(48deg) brightness(90%) contrast(91%);
  }

}
.iconCheckCircleGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(29%) sepia(92%) saturate(6173%) hue-rotate(142deg) brightness(97%) contrast(92%);
  }

}
.iconErrorCircleRed800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(35%) sepia(15%) saturate(2471%) hue-rotate(315deg) brightness(94%) contrast(111%);
  }

}
.iconGoogleGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .content4 {
    filter: brightness(0) saturate(100%) invert(49%) sepia(96%) saturate(2386%) hue-rotate(199deg) brightness(96%) contrast(100%);
  }

  .content3 {
    filter: brightness(0) saturate(100%) invert(49%) sepia(61%) saturate(474%) hue-rotate(84deg) brightness(98%) contrast(92%);
  }

  .content2 {
    filter: brightness(0) saturate(100%) invert(78%) sepia(40%) saturate(1531%) hue-rotate(353deg) brightness(97%) contrast(103%);
  }

  .content1 {
    filter: brightness(0) saturate(100%) invert(30%) sepia(51%) saturate(2309%) hue-rotate(339deg) brightness(104%) contrast(93%);
  }

}
.iconFacebookGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }

}
.iconPlusGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(18%) saturate(1387%) hue-rotate(150deg) brightness(102%) contrast(94%);
  }

}
.iconShowGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(98%) saturate(7465%) hue-rotate(80deg) brightness(86%) contrast(97%);
  }

}
.iconShowGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(55%) sepia(94%) saturate(6910%) hue-rotate(132deg) brightness(89%) contrast(92%);
  }

}
.iconShowGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(84%) sepia(0%) saturate(2032%) hue-rotate(6deg) brightness(100%) contrast(69%);
  }

}
.iconHideGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(91%) saturate(7464%) hue-rotate(24deg) brightness(97%) contrast(93%);
  }

}
.iconHideGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(23%) sepia(78%) saturate(4973%) hue-rotate(140deg) brightness(95%) contrast(92%);
  }

}
.iconHideGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(7487%) hue-rotate(113deg) brightness(79%) contrast(97%);
  }

}
.iconCardGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(1%) saturate(1%) hue-rotate(323deg) brightness(96%) contrast(84%);
  }

}
.iconLocationGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(42%) saturate(1412%) hue-rotate(158deg) brightness(79%) contrast(98%);
  }

}
.iconRestaurantGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(7500%) hue-rotate(274deg) brightness(115%) contrast(98%);
  }

}
.iconClockGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(148deg) brightness(95%) contrast(103%);
  }

}
.iconClockGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(85%) sepia(3%) saturate(18%) hue-rotate(343deg) brightness(88%) contrast(95%);
  }

}
.iconVisaGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(6036%) hue-rotate(231deg) brightness(79%) contrast(89%);
  }

}
.iconMastercardGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content3 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(59%) sepia(57%) saturate(659%) hue-rotate(354deg) brightness(103%) contrast(94%);
  }

  .content2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(2479%) hue-rotate(1deg) brightness(102%) contrast(105%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(7381%) hue-rotate(348deg) brightness(89%) contrast(109%);
  }

}
.iconDiscoverGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
  }

}
.iconAmexGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .conten2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg) brightness(107%) contrast(101%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(28%) sepia(92%) saturate(5461%) hue-rotate(196deg) brightness(97%) contrast(101%);
  }

}
.iconJCBGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content7 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(92%) sepia(99%) saturate(25%) hue-rotate(256deg) brightness(107%) contrast(100%);
  }

  .content6 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(176%) hue-rotate(199deg) brightness(111%) contrast(101%);
  }

  .content5 {
    width: 100%;
    height: 100%;
  }

  .content4 {
    width: 100%;
    height: 100%;
  }

  .content3 {
    width: 100%;
    height: 100%;
  }

  .content2 {
    width: 100%;
    height: 100%;
  }

  .content1 {
    width: 100%;
    height: 100%;
  }

}
.iconUnionPayGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(83%) saturate(3926%) hue-rotate(341deg) brightness(89%) contrast(99%);
  }

  .content3 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(97%) saturate(2317%) hue-rotate(193deg) brightness(101%) contrast(101%);
  }

  .content2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(23%) sepia(84%) saturate(2347%) hue-rotate(166deg) brightness(94%) contrast(101%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(7496%) hue-rotate(176deg) brightness(107%) contrast(106%);
  }

}
.iconPdfGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(76%) saturate(7446%) hue-rotate(360deg) brightness(117%) contrast(98%);
  }

}
.iconHamburgerMenuGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .frame149 {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }

  .content {
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7489%) hue-rotate(298deg) brightness(89%) contrast(100%);
  }

}
.iconProfileGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .union {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(82%) saturate(16%) hue-rotate(213deg) brightness(88%) contrast(100%);
  }

}
.iconCardGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(7472%) hue-rotate(250deg) brightness(112%) contrast(100%);
  }

}
.iconQuestionGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(340deg) brightness(94%) contrast(106%);
  }

}
.iconExitGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .logout {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(23%) sepia(35%) saturate(2827%) hue-rotate(335deg) brightness(113%) contrast(91%);
  }

}
.iconClockGrey700 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(49%) sepia(0%) saturate(1765%) hue-rotate(214deg) brightness(90%) contrast(84%);
  }

}
.iconClockGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7483%) hue-rotate(37deg) brightness(98%) contrast(104%);
  }

}
.iconClockGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(88%) sepia(0%) saturate(0%) hue-rotate(157deg) brightness(90%) contrast(83%);
  }

}
.iconVisaGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(28%) saturate(7207%) hue-rotate(222deg) brightness(107%) contrast(95%);
  }

}
.iconMastercardGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content3 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(58%) sepia(89%) saturate(430%) hue-rotate(354deg) brightness(97%) contrast(100%);
  }

  .content2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(47%) sepia(85%) saturate(4444%) hue-rotate(4deg) brightness(108%) contrast(105%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5472%) hue-rotate(349deg) brightness(89%) contrast(108%);
  }

}
.iconDiscoverGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
  }

}
.iconAmexGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .conten2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg) brightness(107%) contrast(101%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(28%) sepia(92%) saturate(5461%) hue-rotate(196deg) brightness(97%) contrast(101%);
  }

}
.iconJCBGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content7 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(92%) sepia(99%) saturate(25%) hue-rotate(256deg) brightness(107%) contrast(100%);
  }

  .content6 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(176%) hue-rotate(199deg) brightness(111%) contrast(101%);
  }

  .content5 {
    width: 100%;
    height: 100%;
  }

  .content4 {
    width: 100%;
    height: 100%;
  }

  .content3 {
    width: 100%;
    height: 100%;
  }

  .content2 {
    width: 100%;
    height: 100%;
  }

  .content1 {
    width: 100%;
    height: 100%;
  }

}
.iconUnionPayGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(83%) saturate(3926%) hue-rotate(341deg) brightness(89%) contrast(99%);
  }

  .content3 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(97%) saturate(2317%) hue-rotate(193deg) brightness(101%) contrast(101%);
  }

  .content2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(23%) sepia(84%) saturate(2347%) hue-rotate(166deg) brightness(94%) contrast(101%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(7496%) hue-rotate(176deg) brightness(107%) contrast(106%);
  }

}
.iconPdfGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7429%) hue-rotate(241deg) brightness(82%) contrast(115%);
  }

}
.iconClosedGrey800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content7 {
    filter: brightness(0) saturate(100%) invert(86%) sepia(1%) saturate(1879%) hue-rotate(22deg) brightness(90%) contrast(88%);
  }

  .content6 {
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(0%) hue-rotate(130deg) brightness(104%) contrast(105%);
  }

  .content5 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }

  .content4 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(14deg) brightness(103%) contrast(102%);
  }

  .content3 {
    filter: brightness(0) saturate(100%) invert(22%) sepia(0%) saturate(0%) hue-rotate(356deg) brightness(94%) contrast(88%);
  }

  .content2 {
    filter: brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(93%) contrast(76%);
  }

  .content1 {
    filter: brightness(0) saturate(100%) invert(23%) sepia(1%) saturate(456%) hue-rotate(143deg) brightness(93%) contrast(90%);
  }

}
.iconEmailGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .vector {
    width: 100%;
    height: 100%;
    border: 2px solid rgb(255, 255, 255);
  }

}
.iconLinkGrey800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .vector {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(15%) sepia(2%) saturate(609%) hue-rotate(314deg) brightness(99%) contrast(74%);
  }

}
.iconOrderCircleGreen800 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content2 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(99%) sepia(5%) saturate(82%) hue-rotate(309deg) brightness(121%) contrast(100%);
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(28%) sepia(42%) saturate(6895%) hue-rotate(238deg) brightness(104%) contrast(101%);
  }

}
.iconSocialShareGrey1100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7429%) hue-rotate(241deg) brightness(82%) contrast(115%);
  }
}
.iconSocialShareGrey600 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .vector {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(91%) sepia(0%) saturate(450%) hue-rotate(134deg) brightness(83%) contrast(97%);
  }

}
.iconSMSSocialGrey100 {
  align-self: flex-start;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content2 {
    filter: brightness(0) saturate(100%) invert(68%) sepia(28%) saturate(6955%) hue-rotate(209deg) brightness(103%) contrast(104%);
  }

  .content1 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(102%) contrast(102%);
  }

}
.iconEmailSocialGrey100 {
  align-self: flex-start;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content2 {
    filter: brightness(0) saturate(100%) invert(60%) sepia(76%) saturate(460%) hue-rotate(40deg) brightness(92%) contrast(101%);
  }

  .content1 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(150deg) brightness(107%) contrast(101%);
  }

}
.iconFacebookSocialGrey100 {
  align-self: flex-start;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .flogoRGBBlue10241 {
    background-image: url('../../../resources/images/flogoRGBBlue10241.png');
    background-size: cover;
  }

}
.iconTwitterSocialGrey100 {
  align-self: flex-start;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content2 {
    filter: brightness(0) saturate(100%) invert(44%) sepia(99%) saturate(1612%) hue-rotate(179deg) brightness(102%) contrast(88%);
  }

  .content1 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7440%) hue-rotate(8deg) brightness(101%) contrast(113%);
  }

}