.toast {
  z-index: 3;
  position: fixed;
  top: 4.5625rem;
  left: 50%;
  transform: translate(-50%);
  width: fit-content;
}

.toastBody {
  display: block;
  box-sizing: border-box;
}

.toastNotificationSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  align-self: stretch;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(106, 101, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0rem 0rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.toastNotificationError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  align-self: stretch;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(183, 56, 49);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0rem 0rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}