.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 1.5rem 1rem 1rem 1rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .title {
    padding-bottom: 0.5rem;
    p {
      text-align: center;
    }
  }
  .subHeader {
    p {
      color: #747474;
      text-align: center;
    }
    background-color: rgb(255, 255, 255);
  }
  .image {
    min-height: 17rem;
    max-height: 30rem;
    max-width: 34rem;
    align-self: center;
    height: 100%;
    width: 100%;
    background-position-x: 50%;
    background-position-y: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .buttons {
    border-top: 0.1rem solid #DCDCDC;
    padding: 1rem;
    background-color: #fff;
    align-self: stretch;
    width: 100%;
    height: 7.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .continueButton, .shareButton {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    padding: 0.625rem 1rem 0.625rem 1rem;
    margin: 0.25rem 0.5rem 0.25rem 0.5rem;
    > * {
      margin-left: 0.25rem;
      display: flex;
    }
    div {
      align-self: center;
    }
    img {
      height: 1.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}