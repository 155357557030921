.shareSheetModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  // MANUAL OVERRIDE
  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      text-align: left;
    }
  }

  .topContentText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .socialButtons {
    display: flex;
    flex-direction: row;
    justify-content: center; // MANUAL OVERRIDE
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .sMS {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.125rem 0rem 0.125rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    height: 4rem;
    flex: 0 0 3.75rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.125rem 0rem 0.125rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    height: 4rem;
    flex: 0 0 3.75rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .facebook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.125rem 0rem 0.125rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    height: 4rem;
    flex: 0 0 3.75rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .twitter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.125rem 0rem 0.125rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    height: 4rem;
    flex: 0 0 3.75rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .copyLinkField {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 768px) {
  .shareSheetModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .modalHeader {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(255, 255, 255);
    }
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    // MANUAL OVERRIDE
    .text {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      p {
        text-align: center;
      }
    }

    .topContentText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .socialButtons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > * {
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    // MANUAL OVERRIDE
    .sMS {
      display: none;
    }
  
    .email {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > * {
        margin-top: 0.25rem;
      }
      align-self: center;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .facebook {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > * {
        margin-top: 0.25rem;
      }
      align-self: center;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .twitter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > * {
        margin-top: 0.25rem;
      }
      align-self: center;
      height: 4.5rem;
      flex: 0 0 4.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .bottomContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .copyLinkField {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}