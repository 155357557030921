.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 0rem;
  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem;
  }
  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem;
  }
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}

.image {
  align-self: center;
  width: 100%;
  height: 100%;
  max-width: 288px;
  max-height: 192px;
  flex: 1 1 auto;
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media (min-width: 576px) {
    max-width: 436px;
    max-height: 291px;
  }

  @media (min-width: 768px) {
    max-width: 544px;
    max-height: 360px;
  } 

  @media (min-width: 1024px) {
    max-width: 688px;
    max-height: 458px;
  }

  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(255, 255, 255);
}

.content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);
}

.innerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  padding: 1rem 1rem 1rem 1rem;
  overflow: auto;
}

.button {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem 0.625rem 1rem;
  > * {
    margin-left: 0.25rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
}

.topContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.25rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
}

.cartOverview {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
}

.priceListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
}

.cartEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 1 1 auto;
}