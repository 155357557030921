.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1000;
}

.modalDialog {
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex: 0 0 auto;
  height: 100%;
  padding: 0rem;
}

.modalContent {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  height: 100%;
  animation: slidein 0.25s normal ease-in;
}


@media (max-width: 575px) {
  .modalDialog {
    width: 100%;
  }

  .modalContent {
    width: 100%;
  }

  .profileMenu {
    width: 100%;
  }
}

@keyframes slidein {
  0% { margin-left: -100% }

  100% { margin-left: 0% }
}

@-webkit-keyframes slidein {
  0% { margin-left: -100% }

  100% { margin-left: 0% }
}

@keyframes slideout {
  0% { margin-left: 0% }

  100% { margin-left: -100% }
}

@-webkit-keyframes slideout {
  0% { margin-left: 0% }

  100% { margin-left: -100% }
}