.cart {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: none;
  }

}

.cart:hover {
  background-color: rgb(244, 244, 244);
}

.cart:active {
  background-color: rgb(235, 235, 235);
}

.cart:focus-visible {
  background-color: rgb(244, 244, 244);
  outline: 2px solid #6A65FF;
}

.cart:disabled {
  background-color: rgb(189, 189, 189);
}

@media (min-width: 1024px) {
  .cart {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    flex: 0 0 auto;
    > * {
      margin-left: 0.25rem;
    }
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .icon {
      align-self: center;
      height: 1.5rem;
      flex: 0 0 1.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .text {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
  }
}