.restaurantCardDefaultDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(255, 255, 255);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultDefault:hover {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultDefault:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(244, 244, 244);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultDefault:focus-visible {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);
  border: 2px solid rgb(10, 135, 41);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultDefault:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultClosed {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(255, 255, 255);

  .closedRestaurantImage {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 9rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultClosed:hover {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);

  .closedRestaurantImage {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 9rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultClosed:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(244, 244, 244);

  .closedRestaurantImage {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 9rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultClosed:focus-visible {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(250, 250, 250);
  border: 2px solid rgb(106, 101, 255);

  .closedRestaurantImage {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 9rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardDefaultClosed:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .closedRestaurantImage {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 9rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .topContentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .cusineTypeText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .addressText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .icon {
    align-self: flex-start;
    height: 1rem;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .distanceText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}
.restaurantCardLoadingDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(255, 255, 255);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 9rem;
    padding-top: 50%; /* ratio 2:1 */
    background-image: inherit;
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .contentGroupOne {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 1.25rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentGroupTwo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

  .contentGroupThree {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 1rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(235, 235, 235);
    background-size: 200% 100% !important;
    background: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    -webkit-animation: shine 1.5s infinite linear;
    animation: shine 1.5s infinite linear;
  }

}

@media (min-width: 576px) {
  .restaurantCardDefaultDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultDefault:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultDefault:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultDefault:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(10, 135, 41);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultDefault:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}
@media (min-width: 576px) {
  .restaurantCardDefaultClosed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultClosed:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultClosed:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultClosed:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(106, 101, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardDefaultClosed:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 576px) {
  .restaurantCardLoadingDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 8rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroupOne {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1.25rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupTwo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupThree {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .topContentGroup {
      display: none;
    }
  
    .titleText {
      display: none;
    }
  
    .icon {
      display: none;
    }
  
    .cusineTypeText {
      display: none;
    }
  
    .contentGroup {
      display: none;
    }
  
    .addressText {
      display: none;
    }
  
    .rightContent {
      display: none;
    }
  
    .distanceText {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultDefault:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultDefault:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultDefault:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(10, 135, 41);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultDefault:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}
@media (min-width: 768px) {
  .restaurantCardDefaultClosed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultClosed:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultClosed:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultClosed:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(106, 101, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardDefaultClosed:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantCardLoadingDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 10.5rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroupOne {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupTwo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupThree {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .topContentGroup {
      display: none;
    }
  
    .titleText {
      display: none;
    }
  
    .icon {
      display: none;
    }
  
    .cusineTypeText {
      display: none;
    }
  
    .contentGroup {
      display: none;
    }
  
    .addressText {
      display: none;
    }
  
    .rightContent {
      display: none;
    }
  
    .distanceText {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultDefault:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultDefault:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultDefault:focuesd {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(10, 135, 41);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultDefault:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 18.625rem;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: flex-start;
      width: 18.625rem;
      flex: 0 0 3.75rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}
@media (min-width: 1024px) {
  .restaurantCardDefaultClosed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultClosed:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultClosed:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(244, 244, 244);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultClosed:focuesd {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(106, 101, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardDefaultClosed:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: flex-start;
      width: 18.625rem;
      flex: 0 0 3.75rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      width: 18.625rem;
      flex: 0 0 9.25rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantCardLoadingDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 9.25rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroupOne {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1.25rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupTwo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupThree {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      background-color: rgb(235, 235, 235);
    }
  
    .topContentGroup {
      display: none;
    }
  
    .titleText {
      display: none;
    }
  
    .icon {
      display: none;
    }
  
    .cusineTypeText {
      display: none;
    }
  
    .contentGroup {
      display: none;
    }
  
    .addressText {
      display: none;
    }
  
    .rightContent {
      display: none;
    }
  
    .distanceText {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultDefault:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultDefault:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultDefault:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(10, 135, 41);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultDefault:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultClosed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultClosed:hover {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultClosed:pressed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultClosed:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(250, 250, 250);
    border: 2px solid rgb(106, 101, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardDefaultClosed:disabled {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .topContentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .cusineTypeText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .contentGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .addressText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.25rem;
      }
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .icon {
      align-self: flex-start;
      height: 1rem;
      flex: 0 0 1rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .distanceText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .closedRestaurantImage {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 13.125rem;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .image {
      display: none;
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantCardLoadingDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 13.125rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .contentGroupOne {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 1.25rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupTwo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .contentGroupThree {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1rem;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(235, 235, 235);
    }
  
    .topContentGroup {
      display: none;
    }
  
    .titleText {
      display: none;
    }
  
    .icon {
      display: none;
    }
  
    .cusineTypeText {
      display: none;
    }
  
    .contentGroup {
      display: none;
    }
  
    .addressText {
      display: none;
    }
  
    .rightContent {
      display: none;
    }
  
    .distanceText {
      display: none;
    }
  
  }
}

@keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}


@-webkit-keyframes shine {
  0% { background-position-x: 0; }
  100% { background-position-x: -200%; }
}