.restaurantPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .restaurantBanner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .tagMenu {
    position: sticky;
    top: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255);
  }

  .divider {
    overflow: hidden;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .menuListBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.625rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }
}

@media (min-width: 576px) {
  .restaurantPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .restaurantBanner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .tagMenu {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .divider {
      overflow: hidden;
      align-self: stretch;
      flex: 0 0 auto;
      margin: 0rem 1rem;
    }

    .menuListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.625rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: 768px) {
  .restaurantPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .restaurantBanner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .tagMenu {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 2.5rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .divider {
      overflow: hidden;
      align-self: stretch;
      flex: 0 0 auto;
      margin: 0rem 2.5rem;
    }

    .menuListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.625rem 0rem 1.5rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: 1024px) {
  .restaurantPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);

    .restaurantBanner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .tagMenu {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 2.5rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .divider {
      overflow: hidden;
      align-self: stretch;
      flex: 0 0 auto;
      margin: 0rem 2.5rem;
    }

    .menuListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.625rem 0rem 2.5rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: 1440px) {
  .restaurantPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);

    .restaurantBanner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .tagMenu {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 4rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .divider {
      overflow: hidden;
      align-self: stretch;
      flex: 0 0 auto;
      margin: 0rem 4rem;
    }

    .menuListBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.625rem 0rem 4rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
