.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0rem 0rem 1rem 0rem;
  > * {
    margin-top: 0.5rem;
  }
  background-color: rgb(255, 255, 255);

  .menuHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(255, 255, 255);
  }

  .button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .userContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-top: 2.1875rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .userAvatar {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 5rem;
    flex: 0 0 5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-size: contain;
  }

  .userDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .name {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    width: 20rem;
    flex: 0 0 auto;
  }

  .email {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 20rem;
    flex: 0 0 auto;
  }

}