.restaurantListBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }

  .blockTitle {
    font-weight: 600;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .restaurantCardList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .noContent {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
  }


  .image {
    width: 288px;
    height: 192px;

    @media (min-width: 576px) {
      width: 436px;
      height: 291px;
    }

    @media (min-width: 768px) {
      width: 544px;
      height: 360px;
    } 

    @media (min-width: 1024px) {
      width: 688px;
      height: 458px;
    }

    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .restaurantListBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1rem;
    }
  
    .blockTitle {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .restaurantCardList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
        margin-left: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .noRestaurantsFound {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      width: 100%;
  
      .button {
        margin-top: 1rem;
        width: 30rem;
      }
    }
  
  }
}

@media (min-width: 768px) {
  .restaurantListBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
  
    .blockTitle {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .restaurantCardList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
        margin-left: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .noRestaurantsFound {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      width: 100%;
  
      .button {
        margin-top: 1rem;
        width: 42rem;
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .restaurantListBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
  
    .blockTitle {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .restaurantCardList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .noRestaurantsFound {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      width: 100%;
  
      .button {
        margin-top: 1rem;
        width: 42rem;
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .restaurantListBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4rem 4rem 4rem 4rem;
    > * {
      margin-top: 1rem;
    }
  
    .blockTitle {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .restaurantCardList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .noRestaurantsFound {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      width: 100%;
  
      .button {
        margin-top: 1rem;
        width: 42rem;
      }
    }
  
  }
}