// MANUAL OVERRIDE
.closedRestaurantImage {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 9rem;
  padding-top: 50%; /* ratio 2:1 */
  position: relative;
  width: 100%;

  // MANUAL OVERRIDE
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-top: 0.5rem;
    }
    align-self: center;
    flex: 1 1 auto;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    top: 35%;
  }

  .icon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}