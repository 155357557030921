.selectFieldDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(244, 244, 244);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
    color: rgba(116, 116, 116, 1);
  }
}

.selectFieldError {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(244, 244, 244);
    border: 2px red solid;
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
    color: rgba(116, 116, 116, 1);
  }

  .errorText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
