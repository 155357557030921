.buttonItemList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: rgb(255, 255, 255);

  .buttonItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 576px) {
  .buttonItemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 3rem 3rem 3rem;
    background-color: rgb(255, 255, 255);
  
    .buttonItem {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 1rem 1rem 1rem;
      > * {
        margin-left: 0.75rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .buttonItemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
  
    .buttonItem {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 1rem 1rem 1rem;
      > * {
        margin-left: 0.75rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}