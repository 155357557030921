.emptyBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 1.5rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1.5rem;
  }
  background-color: rgb(255, 255, 255);

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 4.5rem;
    flex: 0 0 4.5rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .emptyBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1.5rem;
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 7.5rem;
      flex: 0 0 7.5625rem;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .emptyBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1.5rem;
    }
    background-color: rgb(255, 255, 255);
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 11.25rem;
      flex: 0 0 11.25rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .description {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}