.radioItemList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .radioItem {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-top: 0.5rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    max-width: calc(25% - 0.375rem);
    &:nth-child(-n+4) {
      margin-top: 0;
    }
    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }

}