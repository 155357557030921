.orderProgressItemComplete {
  overflow: hidden;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: #BCB9FF;

}
.orderProgressItemIncomplete {
  overflow: hidden;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: rgb(116, 116, 116);

}
.orderProgressItemNone {
  overflow: hidden;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-left: 0;
  }
}

.orderProgressItemInprogress {
  overflow: hidden;
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-left: 0;
  }
  background-color: rgb(116, 116, 116);
}

.progress {
  background-color: #BCB9FF;
  -webkit-animation: progressBar 3s ease-in-out;
  animation: progressBar 3s infinite ease; 
}

@-webkit-keyframes progressBar {
  0% { height: 0; }
  100% { height: 100%; }
}

@keyframes progressBar {
  0% { height: 0; }
  100% { height: 100%; }
}