.textInputTextDefault {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextDefault:filled {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
  
  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextDefault:active {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(106, 101, 255);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextDefault:error {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(183, 56, 49);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextDefault:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputPasswordDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputPasswordDefault:filled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputPasswordDefault:active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(106, 101, 255);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputPasswordDefault:error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(183, 56, 49);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputPasswordDefault:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextAreaDefault {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextAreaDefault:filled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextAreaDefault:active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(106, 101, 255);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextAreaDefault:error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(183, 56, 49);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextAreaDefault:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextLeadingIcon {
  display: flex;
  font-family: 'Boing', 'sans-serif';
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextLeadingIcon:filled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(244, 244, 244);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextLeadingIcon:active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(106, 101, 255);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}
.textInputTextLeadingIcon:error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 2px solid rgb(183, 56, 49);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text:disabled {
    color: rgb(116, 116, 116);
    -webkit-text-fill-color: rgba(116, 116, 116, 1);
  }
}