.chipDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(200, 245, 120);
  border-radius: 100px;

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: undefined undefined undefined;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}