.menuItemListRadio {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .radioOptionItem {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

}
.menuItemListCheckbox {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .checkboxOptionItem {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 768px) {
  .menuItemListRadio {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .radioOptionItem {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 1rem 0rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .menuItemListCheckbox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  
    .checkboxOptionItem {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 1rem 0rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .radioOptionItem {
      display: none;
    }
  
  }
}