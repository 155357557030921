.helpPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.625rem;
  }
  background-color: rgb(255, 255, 255);

  .helpBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .helpPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .helpBlock {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .helpPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    .helpBlock {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-left: 0.5rem;
      }
      align-self: center;
      width: 36rem;
      flex: 0 0 19.8125rem;
    }
  
  }
}

@media (min-width: 1024px) {
  .helpPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .helpBlock {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-left: 0.5rem;
      }
      align-self: center;
      width: 36rem;
      flex: 0 0 19.8125rem;
    }
  
  }
}

@media (min-width: 1440px) {
  .helpPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .helpBlock {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6rem 6rem 6rem 6rem;
      > * {
        margin-left: 0.5rem;
      }
      align-self: center;
      width: 36rem;
      flex: 0 0 19.8125rem;
    }
  
  }
}