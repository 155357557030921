.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;    
  // important is being used because bootstrap is adding inline css
  padding: 0rem !important;;

  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem !important;;
  }

  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem !important;;
  }
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}

.shareSheetModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  &:nth-child(1) {
    margin-top: 0;
  }

  height: 100%;
}

@keyframes popup {
  0% {
   margin-top: 100%;
  }

  100% {
    margin-top: 0%;
  }
}

@-webkit-keyframes popup {
  0% {
    margin-top: 100%;
  }

  100% {
    margin-top: 0%;
  }
}
// MANUAL OVERRIDE
@media (min-width: 1024px) {
  .modalDialog {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    width: 100%;
    position: relative;
  }
}