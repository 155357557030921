.foodItemHeaderDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  position: sticky;
  top: 0;
  height: 0;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    width: 100%;
  }

  .button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .titleText {
    display: none;
  }

  .closeButton {
    display: none;
  }

  .divider {
    display: none;
  }
}

.foodItemHeaderDefaultAnimated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  position: sticky;
  top: 0;
  height: 0;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    -webkit-animation: fadeOut 0.5s ease-in-out;
    animation: fadeOut 0.5s ease-in-out; 
  }

  .button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-self: center;
    flex: 1 1 auto;
  }

  .closeButton {
    display: none;
  }

  .divider {
    align-self: center;
    width: 100%;
    flex: 0 0 auto;
    background-color: rgba(235, 235, 235, 0);
    -webkit-animation: fadeOutDiv 0.5s ease-in-out;
    animation: fadeOutDiv 0.5s ease-in-out; 
  }

}

.foodItemHeaderScroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  position: sticky;
  top: 0;
  height: 0;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    width: 100%;
    background-color: rgba(255, 255, 255, 100);
    -webkit-animation: fadeIn 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out; 
  }

  .titleText {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-self: center;
    flex: 1 1 auto;
  }

  .closeButton {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .divider {
    align-self: center;
    width: 100%;
    flex: 0 0 auto;
    background-color: rgba(235, 235, 235, 100);
    -webkit-animation: fadeInDiv 0.5s ease-in-out;
    animation: fadeInDiv 0.5s ease-in-out; 
  }
  
  .button {
    display: none;
  }

}

@media (min-width: 768px) {
  .foodItemHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    height: 0;
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      width: 100%;
    }
  
    .titleText {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 1rem 0.5rem 1rem;
      align-self: center;
      flex: 1 1 auto;
    }
  
    .closeButton {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      > * {
        margin-left: 0.25rem;
      }
      align-self: center;
      flex: 0 0 auto;
    }
  
    .divider {
      display: initial;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(235, 235, 235);
    }
  
    .button {
      display: none;
    }
  
  }
}

@-webkit-keyframes fadeIn {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 100);
  }
}

@keyframes fadeIn {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 100);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    background-color: rgba(255, 255, 255, 100);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes fadeOut {
  0% {
    background-color: rgba(255, 255, 255, 100);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes fadeInDiv {
  0% {
    background-color: rgba(235, 235, 235, 0);
  }
  100% {
    background-color: rgba(235, 235, 235, 100);
  }
}

@keyframes fadeInDiv {
  0% {
    background-color: rgba(235, 235, 235, 0);
  }
  100% {
    background-color: rgba(235, 235, 235, 100);
  }
}

@-webkit-keyframes fadeOutDiv {
  0% {
    background-color: rgba(235, 235, 235, 100);
  }
  100% {
    background-color: rgba(235, 235, 235, 0);
  }
}

@keyframes fadeOutDiv {
  0% {
    background-color: rgba(235, 235, 235, 100);
  }
  100% {
    background-color: rgba(235, 235, 235, 0);
  }
}
