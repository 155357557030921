.profileMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: rgb(255, 255, 255);
  height: 100%;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0rem 0rem 1rem 0rem;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
  }

  .buttonItemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 576px) {
  .profileMenu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: rgb(255, 255, 255);
  
    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0rem 0rem 1rem 0rem;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .buttonItemList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 3rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: 1024px) {
  .profileMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: rgb(255, 255, 255);
  
    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0rem 0rem 1rem 0rem;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .buttonItemList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 1rem 1rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}