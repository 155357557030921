body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

html { 
    scroll-behavior: smooth; 
} 