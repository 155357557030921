.changeAddressModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  z-index: 1000;

  .modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background-color: rgb(255, 255, 255);
    padding: 16px;
    flex: 1;

    .addressAutoComplete {
      margin-top: 16px;
    }
    .addressLine2 {
      margin-top: 16px;
    }
    .deliveryInstructions {
      margin-top: 16px;
      width: 100%;
    }
  }

  .buttonContent {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 1rem 1rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem 0.625rem 1rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
