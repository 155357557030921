.toggle {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);
  border-radius: 100px;
  cursor: pointer;

  .secondOption {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .firstOption {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
  }

}