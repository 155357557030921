.menuBlocksList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -2.625rem;
  padding: 2.625rem 0rem 1rem 0rem;

  .menuListItemBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    scroll-margin-top: 2.625rem;
    align-items: flex-start;
    padding: 1rem 1rem 0rem 1rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 576px) {
  .menuBlocksList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
  
    .menuListItemBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 1rem 0rem 1rem;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .menuBlocksList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1.5rem 0rem;
  
    .menuListItemBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 2.5rem 0rem 2.5rem;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .menuBlocksList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 2.5rem 0rem;
  
    .menuListItemBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.5rem 2.5rem 0rem 2.5rem;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1440px) {
  .menuBlocksList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 4rem 0rem;
  
    .menuListItemBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4rem 4rem 0rem 4rem;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}